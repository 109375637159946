import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { demoPagesMenu, mvpPagesMenu } from '../menu';

// {* Mvp pages *}
const MVP = {
	LOGINPHONE: lazy(() => import('../pages/presentation/mvp/login/LoginWithoutPass')),
	LOGIN: lazy(() => import('../pages/presentation/mvp/login')),
	GUIDE: lazy(() => import('../pages/presentation/mvp/login/GuidePage')),
	WELCOME: lazy(() => import('../pages/presentation/mvp/index')),
	DONE: lazy(() => import('../pages/presentation/mvp/RegisterDonePage')),
};

const KIT = {
	REGISTER: lazy(() => import('../pages/presentation/kit/BarcodeInputPage')),
	GUIDE: lazy(() => import('../pages/presentation/kit/KitGuide')),
	CONSENT: lazy(() => import('../pages/presentation/kit/ConsentPage')),
	VIDEO: lazy(() => import('../pages/presentation/kit/VideoGuidePage')),
	PICKUP: lazy(() => import('../pages/presentation/kit/PickGuidePage')),
	ADDRESSINPUT: lazy(() => import('../pages/presentation/kit/AddressInputPage')),
	FINALCHECK: lazy(() => import('../pages/presentation/kit/FinalCheckPage')),
};

const SURVEY = {
	BOARD: lazy(() => import('../pages/presentation/survey/SurveyBoardPage')),
	LOGIN: lazy(() => import('../pages/presentation/survey/login')),
	QUESTION: lazy(() => import('../pages/presentation/survey/question')),
	COMPLETE: lazy(() => import('../pages/presentation/survey/complete')),
};

const RESULT = {
	MAIN: lazy(() => import('../pages/presentation/test-result')),
	TYPESUMMARY: lazy(() => import('../pages/presentation/test-result/TypeSummaryPage')),
	MICROBIOEM: lazy(() => import('../pages/presentation/test-result/MicrobioemTypePage')),
	HEALTHINDEX: lazy(() => import('../pages/presentation/test-result/HealthIndexPage')),
	LIFEINDEX: lazy(() => import('../pages/presentation/test-result/LifeIndexPage')),
	LACTODETAIL: lazy(() => import('../pages/presentation/test-result/LactoDetailPage')),
	IMAGE: lazy(() => import('../pages/presentation/test-result/ImagePage')),
	ADDRESSLACTO: lazy(() => import('../pages/presentation/test-result/AddressInputPage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/mvp/login/Page404')),
};

const presentation: RouteProps[] = [
	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},

	/**
	 * MVP Page
	 */
	// {
	// 	path: mvpPagesMenu.mvp.subMenu.loginWithoutPass.path,
	// 	element: <MVP.LOGINPHONE />,
	// },
	{
		path: mvpPagesMenu.mvp.subMenu.identification.path,
		element: <MVP.GUIDE />,
	},

	{
		path: mvpPagesMenu.mvp.subMenu.welcomeList.path,
		element: <KIT.REGISTER />,
	},
	{
		path: `${mvpPagesMenu.mvp.subMenu.welcome.path}/:testId`,
		element: <MVP.WELCOME />,
	},

	{
		path: `${mvpPagesMenu.mvp.subMenu.registerdone.path}`,
		element: <MVP.DONE />,
	},
	/**
	 * Kit Page
	 */
	{
		path: mvpPagesMenu.kit.subMenu.register.path,
		element: <KIT.REGISTER />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.guide.path}/:testId`,
		element: <KIT.GUIDE />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.consent.path}/:testId`,
		element: <KIT.CONSENT />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.videoguide.path}/:testId`,
		element: <KIT.VIDEO />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.pickupguide.path}/:testId`,
		element: <KIT.PICKUP />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.addressinput.path}/:testId`,
		element: <KIT.ADDRESSINPUT />,
	},
	{
		path: `${mvpPagesMenu.kit.subMenu.finalcheck.path}/:testId`,
		element: <KIT.FINALCHECK />,
	},

	/**
	 * MVP Page > Survey
	 */
	{
		path: `${mvpPagesMenu.survey.subMenu.board.path}/:testId`,
		element: <SURVEY.BOARD />,
	},
	{
		path: mvpPagesMenu.survey.subMenu.login.path,
		element: <SURVEY.LOGIN />,
	},
	{
		path: `${mvpPagesMenu.survey.subMenu.question.path}/:testId`,
		element: <SURVEY.QUESTION />,
	},
	{
		path: `${mvpPagesMenu.survey.subMenu.complete.path}/:testId`,
		element: <SURVEY.COMPLETE />,
	},
	/**
	 * MVP Page > TestResult

	 */
	{
		path: `${mvpPagesMenu.testResult.subMenu.testResult.path}/:testId`,
		element: <RESULT.MAIN />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.typeSummary.path}/:testId`,
		element: <RESULT.TYPESUMMARY />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.microbioemType.path}`,
		element: <RESULT.MICROBIOEM />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.lifeIndex.path}/:testId`,

		element: <RESULT.LIFEINDEX />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.healthIndex.path}/:testId`,
		element: <RESULT.HEALTHINDEX />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.lactoDetail.path}/:productId`,
		element: <RESULT.LACTODETAIL />,
	},
	{
		path: mvpPagesMenu.testResult.subMenu.image.path,
		element: <RESULT.IMAGE />,
	},
	{
		path: `${mvpPagesMenu.testResult.subMenu.addressInput.path}/:productId`,
		element: <RESULT.ADDRESSLACTO />,
	},

	/** ************************************************** */
];
const documentation: RouteProps[] = [
	// {
	// 	path: componentPagesMenu.components.path,
	// 	element: <COMPONENTS_PAGE.COMPONENTS />,
	// },
];
const contents = [...presentation, ...documentation];

export default contents;
