export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const mvpPagesMenu = {
	mvp: {
		id: 'mvpPages',
		text: 'MvpPages',
		path: 'mvp',
		icon: 'Window',
		subMenu: {
			login: {
				id: 'login',
				text: 'Login',
				path: 'login',
				icon: 'ViewArray',
			},
			loginWithoutPass: {
				id: 'loginWithoutPass',
				text: 'LoginWitPhone',
				path: 'member/login',
				icon: 'ViewArray',
			},
			identification: {
				id: 'identification',
				text: 'Identification',
				path: 'identify',
				icon: 'ViewArray',
			},

			welcomeList: {
				id: 'welcomeList',
				text: 'WelcomeList',
				path: 'welcome',
				icon: 'ViewArray',
				hide: true,
			},
			welcome: {
				id: 'welcome',
				text: 'Welcome',
				path: 'welcome',
				icon: 'Book',
			},

			registerdone: {
				id: 'registerdone',
				text: 'Registerdone',
				path: 'registerdone',
				icon: 'ViewArray',
			},
		},
	},
	kit: {
		id: 'kitPages',
		text: 'KitPages',
		path: 'kit',
		icon: 'Window',
		subMenu: {
			register: {
				id: 'register',
				text: 'Register',
				path: '/',
				icon: 'ViewArray',
			},
			// dashboard: {
			// 	id: 'dashboard',
			// 	text: 'Dashboard',
			// 	path: '/',
			// 	icon: 'Dashboard',
			// 	subMenu: null,
			// },
			guide: {
				id: 'guide',
				text: 'Guide',
				path: 'kit/guide',
				icon: 'ViewArray',
			},
			consent: {
				id: 'consent',
				text: 'Consent',
				path: 'kit/consent',
				icon: 'ViewArray',
			},
			videoguide: {
				id: 'videoguide',
				text: 'Videoguide',
				path: 'kit/videoguide',
				icon: 'ViewArray',
			},
			pickupguide: {
				id: 'pickupguide',
				text: 'Pickupguide',
				path: 'kit/pickupguide',
				icon: 'ViewArray',
			},
			addressinput: {
				id: 'addressinput',
				text: 'Addressinput',
				path: 'kit/addressinput',
				icon: 'ViewArray',
			},
			finalcheck: {
				id: 'finalcheck',
				text: 'Finalcheck',
				path: 'kit/finalcheck',
				icon: 'ViewArray',
			},
		},
	},

	survey: {
		id: 'surveyPages',
		text: 'SurveyPages',
		path: 'survey',
		icon: 'Window',
		subMenu: {
			board: {
				id: 'board',
				text: 'Board',
				path: 'survey/board',
				icon: 'ViewArray',
			},
			login: {
				id: 'login',
				text: 'Login',
				path: 'survey/login',
				icon: 'ViewArray',
			},
			question: {
				id: 'question',
				text: 'Question',
				path: 'survey/question',
				icon: 'ViewDay',
			},
			complete: {
				id: 'complete',
				text: 'Complete',
				path: 'survey/complete',
				icon: 'ViewArray',
			},
		},
	},
	testResult: {
		id: 'testResultPages',
		text: 'Result Pages',
		path: 'test-result',
		icon: 'emoji_transportation',
		subMenu: {
			testResult: {
				id: 'testResult',
				text: 'TestResult',
				path: 'test-result/main',
				icon: 'ViewArray',
			},
			typeSummary: {
				id: 'typeSummary',
				text: 'TypeSummary',
				path: 'test-result/typesummary',
				icon: 'ViewArray',
			},
			microbioemType: {
				id: 'microbioemType',
				text: 'MicrobioemType',
				path: 'test-result/microbioemtype',
				icon: 'ViewArray',
			},
			healthIndex: {
				id: 'healthIndex',
				text: 'HealthIndex',
				path: 'test-result/healthindex',
				icon: 'ViewArray',
			},
			lifeIndex: {
				id: 'lifeIndex',
				text: 'LifeIndex',
				path: 'test-result/lifeindex',
				icon: 'ViewArray',
			},
			lactoDetail: {
				id: 'lactoDetail',
				text: 'LactoDetail',
				path: 'test-result/lacto-detail',
				icon: 'ViewArray',
			},
			image: {
				id: 'image',
				text: 'ImagePage',
				path: 'test-result/image',
				icon: 'ViewArray',
			},
			addressInput: {
				id: 'addressInput',
				text: 'AddressInput',
				path: 'test-result/addressInput',
				icon: 'ViewArray',
			},
		},
	},
};

export const demoPagesMenu = {
	pages: {
		id: 'pages',
		text: 'Pages',
		icon: 'Extension',
	},
	listPages: {
		id: 'listPages',
		text: 'List Pages',
		path: 'list-pages',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Boxed List',
				path: 'list-pages/boxed-list',
				icon: 'ViewArray',
			},
			listFluid: {
				id: 'listFluid',
				text: 'Fluid List',
				path: 'list-pages/fluid-list',
				icon: 'ViewDay',
			},
		},
	},
	gridPages: {
		id: 'gridPages',
		text: 'Grid Pages',
		path: 'grid-pages',
		icon: 'Window',
		subMenu: {
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Boxed Grid',
				path: 'grid-pages/boxed',
				icon: 'ViewArray',
			},
			gridFluid: {
				id: 'gridFluid',
				text: 'Fluid Grid',
				path: 'grid-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	editPages: {
		id: 'editPages',
		text: 'Edit Pages',
		path: 'edit-pages',
		icon: 'drive_file_rename_outline ',
		subMenu: {
			editModern: {
				id: 'editModern',
				text: 'Modern Edit',
				path: 'edit-pages/modern',
				icon: 'AutoAwesomeMosaic',
				notification: 'primary',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Boxed Edit',
				path: 'edit-pages/boxed',
				icon: 'ViewArray',
			},
			editFluid: {
				id: 'editFluid',
				text: 'Fluid Edit',
				path: 'edit-pages/fluid',
				icon: 'ViewDay',
			},
			editWizard: {
				id: 'editWizard',
				text: 'Wizard Edit',
				path: 'edit-pages/wizard',
				icon: 'LinearScale',
			},
			editInCanvas: {
				id: 'editInCanvas',
				text: 'In Canvas Edit',
				path: 'edit-pages/in-canvas',
				icon: 'VerticalSplit',
			},
			editInModal: {
				id: 'editInModal',
				text: 'In Modal Edit',
				path: 'edit-pages/in-modal',
				icon: 'PictureInPicture',
			},
		},
	},

	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},

	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},

	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
