import React, {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	// userData: Partial<IUserProps>;
	token?: string;
	setToken?(...args: unknown[]): unknown;
	userState?: boolean;
	setUserState: Dispatch<SetStateAction<boolean>>;
	// isAuthenticated: () => Promise<PurchaseDataType>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('impUid') || '');
	const [token, setToken] = useState<string>(localStorage.getItem('surveyAccessToken') || '');

	const [userState, setUserState] = useState(false);

	useEffect(() => {
		localStorage.setItem('impUid', user);
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			token,
			setToken,
			userState,
			setUserState,
			// isAuthenticated,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user, token, userState],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	// @ts-ignore
	children: PropTypes.node.isRequired,
};

export default AuthContext;
