import React, { lazy, useContext } from 'react';
import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import AuthContext from '../../contexts/authContext';

const PAGE_404 = lazy(() => import('../../pages/presentation/mvp/login/Page404'));
const LOGIN = lazy(() => import('../../pages/presentation/mvp/login'));
const LOGINPHONE = lazy(() => import('../../pages/presentation/mvp/login/LoginWithoutPass'));

const ProtectedRoute = ({ token, location }: any) => {
	if (!token) {
		return <Navigate to='/v/intro' replace />;
	}
	return <Outlet />;
};

const MoveToOutLink = ({ location }: any) => {
	// if (location.pathname === '') {
	// 	const moveUrl = `https://p3.chekit.link/v/intro`;
	// 	window.location.href = moveUrl;
	// 	return null;
	// } else
	if (location.pathname && location.pathname.includes('/v/')) {
		// const moveUrl = `https://p3.chekit.link${location.pathname}`;
		const moveUrl = `https://prod.chekit.link${location.pathname}`;
		window.location.href = moveUrl;
		return null;
	}
	return <PAGE_404 />;
};

const ContentRoutes = () => {
	const { token } = useContext(AuthContext); // impUid
	const location = useLocation();

	return (
		<Routes>
			<Route element={<ProtectedRoute token={token} />}>
				{contents.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				))}
			</Route>
			{/* <Route path='' element={<MoveToOutLink location={location} />} /> */}
			<Route path='login' element={<LOGIN />} />
			<Route path='member/login' element={<LOGINPHONE />} />

			<Route path='/v/*' element={<MoveToOutLink location={location} />} />
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
