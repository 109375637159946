import React from 'react';
import { RouteProps } from 'react-router-dom';
// import { demoPagesMenu, mvpPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
// import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	// { path: demoPagesMenu.page404.path, element: null },

	{ path: `*`, element: null },
];

export default asides;
